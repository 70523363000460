const Pages = {
    START: 'start',
    ABOUT: 'about',
    SKILLS: 'skills',
    EXPERIENCES: 'experiences',
    LANGUAGES: 'languages',
    HOBBIES: 'hobbies',
    CLIENTS: 'clients',
    PROJECTS: 'projects',
    SERVICES: 'services',
    TESTIMONIALS: 'testimonials',
    CONTACT: 'contact'
}

export default Pages
