<template>
    <!-- Section - Services -->
    <section id="section-services" data-anchor="services" class="section section-slider fullheight dark border-top" data-scheme="dark">
        <!-- Section Content -->
        <div class="section-content">
            <!-- Slides -->
            <div class="section-slides" data-local-scroll>
                <template v-for="(service, index) in services" :key="service.ID">
                    <div class="slide" :class="`${index % 2 == 0 ? ' dark' : ' light'}`">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <h2 class="display-4">{{ service.name }}</h2>
                                    <p class="lead text-muted">{{ service.line }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!-- Section Backgrounds -->
        <div class="section-bgs">
            <template v-for="(service, index) in services" :key="service.ID">
                <div class="slide" :class="`${index % 2 == 0 ? ' bg-dark' : ' bg-light'}`">
                    <div class="image right col-md-6 push-md-6">
                        <div class="bg-image-container">
                            <img :src="require('@/assets/imgs/services/' + service.img)" alt="" />
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import { NetServices } from '@/network/netservices'
import Pages from '@/utils/pages'

export default {
    data() {
        return {
            services: []
        }
    },

    async beforeCreate() {
        const services = await NetServices.get('services')
        this.services = services.data
        this.$store.commit('initialized', Pages.SERVICES)
    }
}
</script>
