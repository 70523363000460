<template>
    <div id="content">
        <StartView />
        <ResumeView />
        <PortfolioView />
        <ServicesView />
        <TestimonialsView />
        <ContactView />
    </div>
</template>

<script>
import StartView from '@/views/StartView.vue'
import ContactView from '@/views/contact/ContactView.vue'
import PortfolioView from '@/views/portfolio/PortfolioView.vue'
import ResumeView from '@/views/resume/ResumeView.vue'
import ServicesView from '@/views/services/ServicesView.vue'
import TestimonialsView from '@/views/testimonials/TestimonialsView.vue'

export default {
    name: 'ContentView',
    components: {
        StartView,
        ResumeView,
        TestimonialsView,
        ServicesView,
        PortfolioView,
        ContactView
    }
}
</script>
