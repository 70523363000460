<template>
    <div class="resume-box image-edge text-center">
        <h5 class="mb-5">Experience</h5>

        <div class="row">
            <div class="col-md-10 push-md-1">
                <div class="image left">
                    <img src="@/assets/imgs/resume/docs.png" alt="" class="animated" data-animation="fadeInLeft" />
                </div>

                <div class="image right">
                    <img src="@/assets/imgs/resume/plant2.png" alt="" class="animated" data-animation="fadeInRight" />
                </div>

                <div class="timeline">
                    <div class="timeline-event" v-for="experience in experiences" :key="experience.id">
                        <div class="timeline-event-point"></div>
                        <div class="timeline-event-date">{{ experience.startingDate }} - {{ experience.finishingDate }}</div>
                        <div class="timeline-event-content">
                            <h5 class="timeline-event-title">{{ experience.position }}</h5>
                            <span class="timeline-event-caption text-muted">{{ experience.company }} - {{ experience.city }}({{ experience.country }})</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['experiences']
}
</script>
