<template>
    <section id="section-resume" data-anchor="resume" class="section" data-scheme="light">
        <div class="section-content">
            <div class="container-slim">
                <About :about="about" />
                <Skills :skills="skills" />
                <Experience :experiences="experiences" />
                <Languages :languages="languages" />
                <Hobbies :hobbies="hobbies" />
                <Clients :clients="clients" />
            </div>
        </div>
    </section>
</template>

<script>
import About from '@/views/resume/AboutView.vue'
import Clients from '@/views/resume/ClientsView.vue'
import Experience from '@/views/resume/ExperienceView.vue'
import Hobbies from '@/views/resume/HobbiesView.vue'
import Languages from '@/views/resume/LanguagesView.vue'
import Skills from '@/views/resume/SkillsView.vue'

import Pages from '@/utils/pages'

import { ConcurrentRequests, NetServices } from '@/network/netservices'

export default {
    name: 'ResumeView',
    components: {
        About,
        Skills,
        Experience,
        Languages,
        Hobbies,
        Clients
    },

    data() {
        return {
            about: {},
            skills: [],
            experiences: [],
            languages: [],
            hobbies: [],
            clients: []
        }
    },

    async beforeCreate() {
        const aboutRequest = NetServices.get('about')
        const skillsRequest = NetServices.get('skills')
        const experiencesRequest = NetServices.get('experiences')
        const languagesRequest = NetServices.get('languages')
        const hobbiesRequest = NetServices.get('hobbies')
        const clientsRequest = NetServices.get('clients')
        const [about, skills, experiences, languages, hobbies, clients] = await ConcurrentRequests([aboutRequest, skillsRequest, experiencesRequest, languagesRequest, hobbiesRequest, clientsRequest])

        this.about = about.data
        this.skills = skills.data
        this.experiences = experiences.data
        this.languages = languages.data
        this.hobbies = hobbies.data
        this.clients = clients.data

        this.$store.commit('initialized', Pages.ABOUT)
        this.$store.commit('initialized', Pages.SKILLS)
        this.$store.commit('initialized', Pages.EXPERIENCES)
        this.$store.commit('initialized', Pages.LANGUAGES)
        this.$store.commit('initialized', Pages.HOBBIES)
        this.$store.commit('initialized', Pages.CLIENTS)
    }
}
</script>
