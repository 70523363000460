<template>
  <router-view />
</template>

<style lang="scss">
// CSS Plugins
@import '@/assets/css/bootstrap.min.css';
@import '@/assets/css/animate.min.css';
@import '@/assets/css/slick.css';
@import '@/assets/css/jquery.fullpage.min.css';

// CSS Icons
@import '@/assets/css/themify-icons.css';
@import '@/assets/css/font-awesome.min.css';

// CSS Base
@import '@/assets/css/theme-orange.css';
</style>
