<template>
    <div class="resume-box image-edge row">
        <div class="col-md-9">
            <!-- Image -->
            <div class="image right">
                <img src="@/assets/imgs/resume/ipad.png" alt="" class="animated" data-animation="fadeInRight" />
            </div>
            <h5 class="mb-5">Languages</h5>

            <div class="row">
                <div class="col-md-4" v-for="language in languages" :key="language.id">
                    <div class="single-chart">
                        <div class="chart" data-bar-color="#D96BE5" data-track-color="#EEEEEE" :data-percent="`${(language.score * 100) / 5}`">
                            <div class="chart-content">
                                <span class="value"
                                    ><span>{{ language.score }}</span
                                    >/5</span
                                >
                                <h6 class="title">{{ language.name }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['languages']
}
</script>
