<template>
    <div class="resume-box image-edge row">
        <div class="col-md-8 push-md-4">
            <!-- Image -->
            <div class="image left">
                <img src="@/assets/imgs/resume/coffee.png" alt="" class="animated" data-animation="fadeInLeft" />
            </div>
            <h5 class="mb-5">Technical Skills</h5>

            <template v-for="skill in skills" v-bind:key="skill.id">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" :style="`width:${skill.percentage}`" :aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                        <span>{{ skill.percentage }}</span>
                    </div>
                </div>
                <h6 class="mb-4">{{ skill.name }}</h6>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: ['skills']
}
</script>
