<template>
    <PageLoader />
    <NavMenu />
    <NavButton />
    <ContentView />
</template>

<script>
import NavButton from '@/components/main/NavButton.vue'
import NavMenu from '@/components/main/NavMenu.vue'
import PageLoader from '@/components/main/PageLoader.vue'
import ContentView from '@/views/ContentView.vue'

export default {
    name: 'MainView',
    components: {
        PageLoader,
        NavMenu,
        NavButton,
        ContentView
    }
}
</script>
