<template>
    <!-- Section - Testimonials -->
    <section id="section-testimonials" data-anchor="testimonials" class="section bg-primary-animated dark" data-scheme="dark">
        <!-- BG Image -->
        <div class="bg-container bg-primary-animated"></div>
        <div class="bg-image-container bg-multiply"><img src="@/assets/imgs/testimonials/home01.jpg" alt="" /></div>

        <!-- Section Content -->
        <div class="section-content">
            <div class="container">
                <!-- Section Header -->
                <div class="section-header">
                    <img src="@/assets/imgs/avatar-sm.jpg" alt="" class="section-header-image" />
                    <div class="section-header-content">
                        <span class="typing">Unveiling the impact of our collaboration..</span>
                    </div>
                </div>
            </div>
            <div class="container-slim">
                <div class="testimonial" v-for="testimonial in testimonials" :key="testimonial.ID">
                    <p class="testimonial-content">{{ testimonial.endorsement }}</p>
                    <div class="testimonial-author">
                        <img :src="require('@/assets/imgs/testimonials/avatars/' + testimonial.avatar)" alt="" class="testimonial-author-image" />
                        <div class="testimonial-author-content">
                            <h6 class="name">{{ testimonial.author }} - {{ testimonial.position }}</h6>
                            <span class="caption">{{ testimonial.company }} ({{ testimonial.location }})</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { NetServices } from '@/network/netservices'
import Pages from '@/utils/pages'

export default {
    data() {
        return {
            testimonials: []
        }
    },

    async beforeCreate() {
        const testimonials = await NetServices.get('testimonials')
        this.testimonials = testimonials.data
        this.$store.commit('initialized', Pages.TESTIMONIALS)
    }
}
</script>
