<template>
    <section id="section-portfolio" data-anchor="portfolio" class="section bg-dark dark pb-0" data-scheme="dark">
        <div class="section-content">
            <div class="container">
                <!-- Section Header -->
                <div class="section-header">
                    <img src="@/assets/imgs/avatar-sm.jpg" alt="" class="section-header-image" />
                    <div class="section-header-content">
                        <span class="typing">Some of the exciting projects that have crossed my desk...</span>
                        <!-- Filter - Isotope -->
                        <ul class="nav nav-pills filter show-after-typing" data-filter-list="#works-list">
                            <li class="nav-item"><a href="#" class="nav-link active" data-filter="*">All</a></li>
                            <template v-for="(sector, index) in sectors" :key="index">
                                <li class="nav-item">
                                    <a href="#" class="nav-link" :data-filter="`.${sector}`">{{ sector }}</a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="works-list" class="row no-gutters filter-list">
                <template v-for="project in projects" :key="project.ID">
                    <div :class="'col-md-4 col-sm-6 ' + `${project.sector}`">
                        <!-- Image Box -->
                        <figure class="image-box inner-title hover">
                            <div class="image">
                                <a :href="`${project.appLink}`" target="blank">
                                    <img :src="require('@/assets/imgs/portfolio/' + `${project.logo}`)" alt="" />
                                    <img :src="require('@/assets/imgs/portfolio/' + `${project.logo}`)" alt="" />
                                </a>
                            </div>
                            <div class="content text-center">
                                <h5 class="title">
                                    <a :href="`${project.appLink}`" target="blank">{{ project.name }}</a>
                                </h5>
                                <span class="caption text-muted">{{ project.sector }}</span>
                            </div>
                        </figure>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import { NetServices } from '@/network/netservices'

export default {
    data() {
        return {
            projects: []
        }
    },

    async beforeCreate() {
        const projects = await NetServices.get('projects')
        this.projects = projects.data
        this.$store.commit('initialized', 'projects')
    },

    computed: {
        sectors() {
            return this.projects.map(function (project) {
                return project.sector
            })
        }
    }
}
</script>
