<template>
    <nav id="navigation" class="navigation-panel dark">
        <div class="navigation-inner">
            <!-- Navigation Primary -->
            <ul class="nav nav-primary-panel">
                <li data-menuanchor="start" class="nav-item"><a href="#start" class="nav-link">Start</a></li>
                <li data-menuanchor="resume" class="nav-item"><a href="#resume" class="nav-link">Resume</a></li>
                <li data-menuanchor="portfolio" class="nav-item"><a href="#portfolio" class="nav-link">Portfolio</a></li>
                <li data-menuanchor="services" class="nav-item"><a href="#services" class="nav-link">Services</a></li>
                <li data-menuanchor="testimonials" class="nav-item"><a href="#testimonials" class="nav-link">Testimonials</a></li>
                <li data-menuanchor="contact" class="nav-item"><a href="#contact" class="nav-link">Contact</a></li>
            </ul>
        </div>
    </nav>
</template>
