<template>
    <div class="resume-box image-edge text-center">
        <h5 class="mb-5">Hobbies &amp; Interests</h5>
        <!-- Image -->
        <div class="image left">
            <img src="@/assets/imgs/resume/camera.png" alt="" class="animated" data-animation="fadeInLeft" />
        </div>
        <!-- Hobby -->
        <div class="hobby" v-for="hobby in hobbies" :key="hobby.id">
            <div class="hobby-image">
                <div class="bg-image-container"><img :src="require('@/assets/imgs/resume/hobbies/' + hobby.img)" alt="" /></div>
            </div>
            <div class="hobby-image-hover">
                <div class="bg-image-container"><img :src="require('@/assets/imgs/resume/hobbies/' + hobby.imgOver)" alt="" /></div>
            </div>
            <div class="hobby-body">
                <div class="hobby-content">
                    <h4 class="hobby-title">{{ hobby.name }}</h4>
                    <span class="hobby-caption">{{ hobby.line }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['hobbies']
}
</script>
