<template>
    <section id="section-contact" data-anchor="contact" class="section section-contact fullheight bg-dark dark" data-scheme="dark">
        <!-- Section Content -->
        <div class="section-content pos-v-center">
            <div class="container">
                <!-- Section Header -->
                <div class="section-header">
                    <img src="@/assets/imgs/avatar-sm.jpg" alt="" class="section-header-image" />
                    <div class="section-header-content">
                        <span class="typing">Get in touch!</span>
                        <div class="section-header-caption show-after-typing222">
                            <div class="module">
                                <a :href="`mailto:${contact.email}`" class="btn btn-outline-primary btn-sm">Send me an email!</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Section Contact -->
                <div class="row section-contact-content pt-lg-5 pb-lg-5">
                    <div class="col-md-4">
                        <h6 class="text-muted mb-2">Address:</h6>
                        <address class="lead">{{ contact.city }} - {{ contact.country }}</address>
                    </div>
                    <div class="col-md-4">
                        <h6 class="text-muted mb-2">E-mail:</h6>
                        <p class="mb-5">
                            <a href="#" class="lead link-inherit">{{ contact.email }}</a>
                        </p>
                        <h6 class="text-muted mb-2">Phone:</h6>
                        <a href="#" class="lead link-inherit">{{ contact.phone }}</a>
                    </div>
                    <div class="col-md-4">
                        <h6 class="text-muted mb-2">Find me on:</h6>
                        <a :href="`${contact.x}`" target="_blank" class="icon icon-circle icon-hover icon-sm icon-twitter"><i class="fa fa-twitter"></i></a>
                        <a :href="`${contact.linkedin}`" target="_blank" class="icon icon-circle icon-hover icon-sm icon-linkedin"><i class="fa fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { NetServices } from '@/network/netservices'
import Pages from '@/utils/pages'

export default {
    data() {
        return {
            contact: {}
        }
    },

    async beforeCreate() {
        const contact = await NetServices.get('/contact')
        this.contact = contact.data
        this.$store.commit('initialized', Pages.CONTACT)
    }
}
</script>
