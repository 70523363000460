import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '@/views/HomeView.vue'
import MainView from '@/views/MainView.vue'

const routes = [
    {
        path: '/:catchAll(.*)',
        name: 'main',
        component: MainView
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
