<template>
    <div class="resume-box">
        <p class="lead text-lg">{{ about.headline }}</p>
        <p class="lead mb-5">{{ about.summary }}</p>
    </div>
</template>

<script>
export default {
    props: ['about']
}
</script>
