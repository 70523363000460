<template>
    <div class="resume-box image-edge text-center">
        <h5 class="mb-5">Clients</h5>
        <!-- Image -->
        <div class="image right">
            <img src="@/assets/imgs/resume/book.png" alt="" class="animated" data-animation="fadeInRight" />
        </div>
        <div class="row">
            <div class="col-md-10 push-md-1">
                <ul class="spread-images">
                    <li v-for="client in clients" :key="client.id">
                        <a href="#"><img :src="require('@/assets/imgs/resume/clients/' + client.logo)" alt="" class="gray-to-color" /></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['clients']
}
</script>
