import Pages from '@/utils/pages'
import { createStore } from 'vuex'

export default createStore({
    state: {
        uninitialized: [Pages.START, Pages.ABOUT, Pages.SKILLS, Pages.EXPERIENCES, Pages.LANGUAGES, Pages.HOBBIES, Pages.PROJECTS, Pages.SERVICES, Pages.TESTIMONIALS, Pages.CONTACT]
    },
    getters: {},
    mutations: {
        initialized(state, payload) {
            state.uninitialized.splice(state.uninitialized.indexOf(payload), 1)
        }
    },
    actions: {},
    modules: {}
})
